// index3.component.ts
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LangService, Language } from './../../shared/lang.service';

@Component({
  selector: 'app-index3',
  templateUrl: './index3.component.html',
  styleUrls: ['./index3.component.scss'],
  encapsulation: ViewEncapsulation.None,
  styles: [`
    .dark-modal .modal-content {
      background-color: #000000;
      color: white;
      background: none;
      border: none;
    }
    .dark-modal .modal-header {
      border: none;
    }
    .dark-modal .close {
      color: white;
    }
    .dark-modal .modal-dialog {
      max-width: 800px;
    }
  `]
})
export class Index3Component implements OnInit {
  currentSection = 'home';
  languages: Language[];
  currentLanguage: string;

  constructor(private modalService: NgbModal, private langService: LangService) {
    this.languages = this.langService.supportedLanguages;
    this.currentLanguage = this.langService.languageShorthand;
  }

  ngOnInit(): void {
    this.langService.init(); // Automatically sets language based on location
  }

  windowScroll() {
    const navbar = document.getElementById('navbar');
    if (document.body.scrollTop >= 50 || document.documentElement.scrollTop > 50) {
      navbar.classList.add('nav-sticky');
    } else {
      navbar.classList.remove('nav-sticky');
    }
  }

  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }

  loginModal(content) {
    this.modalService.open(content, { centered: true });
  }

  registerModal(registercontent) {
    this.modalService.open(registercontent, { centered: true });
  }

  openWindowCustomClass(videocontent) {
    this.modalService.open(videocontent, { windowClass: 'dark-modal', centered: true });
  }

  onLanguageChange(lang: Language) {
    this.langService.language = lang.code;
    this.currentLanguage = this.langService.languageShorthand;
  }
}
