// lang.service.ts
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import en from '../../assets/lang/en-US.json';
import de from '../../assets/lang/de-DE.json';

const languageKey = '__lang';

// Define the Language interface here
export interface Language {
  code: string;
  direction: string;
  label: string;
  shorthand: string;
  flag: string;
}

@Injectable({
  providedIn: 'root'
})
export class LangService {
  isSingleLang = false;
  renderer: Renderer2;
  defaultLanguage = 'en-US';

  supportedLanguages: Language[] = [
    { code: 'en-US', direction: 'ltr', label: 'English', shorthand: 'English', flag: './../../assets/img/flags/us.png' },
    { code: 'de-DE', direction: 'ltr', label: 'Deutsch', shorthand: 'Deutsch', flag: './../../assets/img/flags/de.png' },
  ];

  constructor(private translate: TranslateService, private rendererFactory: RendererFactory2, private router: Router) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  init() {
    this.translate.setTranslation('en-US', en);
    this.translate.setTranslation('de-DE', de);
    this.translate.setDefaultLang(this.defaultLanguage);
    this.checkUserLocation();
  }

  private checkUserLocation() {
    if (!localStorage.getItem(languageKey)) {
      // Fetch user's location based on IP address
      fetch('https://ipapi.co/json/')
        .then(response => response.json())
        .then(data => {
          const language = data.country === 'DE' ? 'de-DE' : 'en-US';
          this.setLanguage(language);
        })
        .catch(() => {
          // Default to English if there's an error
          this.setLanguage(this.defaultLanguage);
        });
    } else {
      this.setLanguage(localStorage.getItem(languageKey) || this.defaultLanguage);
    }
  }

  private setLanguage(language: string) {
    const isSupportedLanguage = this.supportedLanguages.some(item => item.code === language);
    const selectedLanguage = isSupportedLanguage ? language : this.defaultLanguage;

    this.translate.use(selectedLanguage);
    localStorage.setItem(languageKey, selectedLanguage);
    this.checkForDirectionChange();
  }

  checkForDirectionChange() {
    const direction = this.supportedLanguages.find(item => item.code === this.translate.currentLang)?.direction || 'ltr';
    this.renderer.removeClass(document.body, 'ltr');
    this.renderer.removeClass(document.body, 'rtl');
    this.renderer.addClass(document.body, direction);
    this.renderer.setAttribute(document.documentElement, 'direction', direction);
  }

  set language(lang: string) {
    if (this.supportedLanguages.some(item => item.code === lang)) {
      this.setLanguage(lang);
    }
  }

  get language(): string {
    return this.translate.currentLang;
  }

  get languageShorthand(): string {
    return this.supportedLanguages.find(item => item.code === this.translate.currentLang)?.shorthand || 'English';
  }
}
